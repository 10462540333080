import React from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  SimpleGrid,
} from '@chakra-ui/react'
import { graphql, Link } from 'gatsby'
import BlogPostCard from '@/components/BlogPostCard/BlogPostCard'
import BaseLayout from '../layout/base'
import SEO from '../components/SEO/PageSEO'
// import coverImg from '../images/extreme-5497194_1920.jpg'

const Page = ({ location, data }) => (
  <BaseLayout>
    <SEO
      title="Generadores aleatorios"
      titleSeo="Generadores aleatorios"
      description="Herramientas para generar cosas al azar en español y en inglés: números, palabras, nombres, listas, etc."
      image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
      // lang="es"
      datePublished="2021-05-11T12:29:33-03:00"
      dateModified="2021-05-11T12:29:33-03:00"
      // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
      permalink={location.pathname}
      // breadcrumbList={[]}
      // noindex={false}
    />
    <Container maxW="5xl">
      <Stack
        // textAlign="center"
        // align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
          as="span"
        >
          <Text as="span" color="orange.400">
            Generadores
          </Text>{' '}
          aleatorios
        </Heading>
        <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
          Herramientas para generar cosas al azar
        </Heading>

        <Divider />

        <Container maxW="5xl" my={10}>
          <SimpleGrid columns={[1, 2, 3]} spacing={10}>
            <BlogPostCard
              category="Contraseñas"
              title="Generador de contraseñas seguras"
              text="Generar claves al azar que sean fuertes y seguras"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/argentina/"
            />
            <BlogPostCard
              category="Teléfonos"
              title="Generador de números de teléfono"
              text="Generar números de teléfono al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/argentina/"
            />
            <BlogPostCard
              category="Tarjetas de crédito"
              title="Generador de tarjetas de crédito"
              text="Generar de tarjetas de crédito al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/argentina/"
            />

            <BlogPostCard
              category="Nombres"
              title="Generador de nombres aleatorios"
              text="Generar nombres de personas al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de mujer aleatorios"
              text="Generar nombres de mujeres al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/mujer/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de hombre aleatorios"
              text="Generar nombres de hombres al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/hombre/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de fantasía"
              text="Generar nombres de fantasía al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/fantasia/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres para empresas"
              text="Generar nombres de empresas y negocios al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/empresas/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres para Instagram"
              text="Generar nombres de usuario para Instagram al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/instagram/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres para juegos"
              text="Generar nombres para videojuegos al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/juegos/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres para Free Fire"
              text="Generar nombres para Free Fire al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/juegos/free-fire/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de España"
              text="Generar nombres de personas de España al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/espana/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de México"
              text="Generar nombres de personas de México al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/mexico/"
            />
            <BlogPostCard
              category="Nombres"
              title="Generador de nombres de Argentina"
              text="Generar nombres de personas de Argentina al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/nombres/pais/argentina/"
            />

            <BlogPostCard
              category="Palabras"
              title="Generador de palabras aleatorias"
              text="Generar palabras en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de adjetivos aleatorios"
              text="Generar adjetivos en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/adjetivos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de sustantivos aleatorios"
              text="Generar sustantivos en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/sustantivos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de verbos aleatorios"
              text="Generar verbos en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/verbos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de palabras inventadas aleatorias"
              text="Generar palabras inventadas en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/inventadas/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de palabras cada 10 segundos"
              text="Generar palabras cada diez segundos en español al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/cada-10-segundos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de palabras en inglés aleatorias"
              text="Generar palabras en inglés al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/ingles/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de adjetivos en inglés aleatorios"
              text="Generar adjetivos en inglés al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/ingles/adjetivos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de sustantivos en inglés aleatorios"
              text="Generar sustantivos en inglés al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/ingles/sustantivos/"
            />
            <BlogPostCard
              category="Palabras"
              title="Generador de verbos en inglés aleatorios"
              text="Generar verbos en inglés al azar"
              image="/images/generador-aleatorio-placeholder.png"
              url="/palabras/ingles/verbos/"
            />
          </SimpleGrid>
        </Container>

        {/* <h2>Primera imagen</h2>
        <p>
          <img src={coverImg} alt="" />
        </p>
        <h2>Segunda imagen</h2>
        <p>
          <img
            // src={`${data.site.siteMetadata.siteUrl}${data.file.childImageSharp.gatsbyImageData.images.fallback.src}`}
            src={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
            alt=""
          />
        </p>
        <hr /> */}
        {/* {data.allSitePage.edges
          .sort((pageA, pageB) => {
            const urlA = pageA.node.path
            const urlB = pageB.node.path
            return urlA.localeCompare(urlB)
          })
          .map(page => (
            <Link to={page.node.path} key={page.node.path}>
              {page.node.path}
            </Link>
          ))} */}
      </Stack>
    </Container>
  </BaseLayout>
)

export default Page

export const pageQuery = graphql`
  query {
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
