import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  // Avatar,
  useColorModeValue,
  // Link as ChakraLink,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/react'

const BlogPostCard = ({ category, title, text, image, url }) => (
  <Center py={6}>
    <LinkBox as="article">
      {/* <LinkBox as="article" maxW="sm" p="0" borderWidth="0" rounded="md"> */}
      <Box
        maxW="445px"
        w="full"
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow="2xl"
        rounded="md"
        p={6}
        overflow="hidden"
      >
        <Box
          h="210px"
          bg="gray.100"
          mt={-6}
          mx={-6}
          mb={6}
          pos="relative"
          // alignItems="center"
          align="center"
        >
          {/* <GatsbyImage image={image} alt={title} layout="fill" /> */}
          {/* <GatsbyImage image={image} alt={title} layout="fill" loading="lazy" /> */}
          <StaticImage
            src="../../../static/images/generador-aleatorio-placeholder.png"
            alt={title}
            // layout="fullWidth"
            // loading="lazy"
            placeholder="blurred"
            height={210}
          />
        </Box>
        <Stack>
          <Text
            color="orange.400"
            textTransform="uppercase"
            fontWeight={800}
            fontSize="sm"
            letterSpacing={1.1}
          >
            {category}
          </Text>
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize="2xl"
            fontFamily="body"
          >
            {/* <ChakraLink as={Link} to={link}>{title}</ChakraLink> */}
            <LinkOverlay as={Link} to={url}>
              {title}
            </LinkOverlay>
          </Heading>
          <Text color="gray.500">{text}</Text>
        </Stack>
        {/* <Stack mt={6} direction="row" spacing={4} align="center">
        <Avatar
          src="https://avatars0.githubusercontent.com/u/1164541?v=4"
          alt="Author"
        />
        <Stack direction="column" spacing={0} fontSize="sm">
          <Text fontWeight={600}>Achim Rolle</Text>
          <Text color="gray.500">Feb 08, 2021 · 6min read</Text>
        </Stack>
      </Stack> */}
      </Box>
    </LinkBox>
  </Center>
)

export default BlogPostCard
